<template>
  <div class="admin-wrapper" :class="{open: isShow}">
    <BuySellDeal :showModal="showModal" @closeModal="closeModal" @open-trade="isOpenTrade()"/>
    <div class="container-fluid">
      <vue-element-loading class="row mrl-0" :active="!(symbols.length && filteredSymbols.length)" spinner="bar-fade-scale" size="64" background-color="#efeffd" color="#ffc008"/>
      <div class="row mrl-0">
          <div class="card border-0 shadow-sm">
            <div class="card-body p-4">
              <div id="history" class="w-100 mb-3">
                <div class="tab-content">
                  <div v-if="$route.name == 'Stock'">
                    <v-select
                      class="form-control bg-gray border-0 mb-4"
                      label="view"
                      v-model="selectedCountry"
                      :options="countryIds"
                      item-text="view"
                      item-value="id"
                      @change="log($event, $event.raw)"
                    ></v-select>
                  </div>
                  <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab--1">
                    <div class="table-responsive border shadow-sm dashboard-table activity-table">
                      <table class="table mb-0">
                        <tbody>
                          <tr>
                            <th><span class="d-inline-block fw-bold fz-14">{{ $t("dashboard.pair") }}</span></th>
                            <td><span class="d-inline-block fw-bold fz-14">{{ $t("dashboard.bid") }}</span></td>
                            <td><span class="d-inline-block fw-bold fz-14"></span></td>
                            <td><span class="d-inline-block fw-bold fz-14">{{ $t("dashboard.ask") }}</span></td>
                            <td><span class="d-inline-block fw-bold fz-14"></span></td>
                          </tr>
                          <tr v-for="symbol in filteredSymbols" v-bind:key="symbol.id" >
                            <th class="symbol-name" @click="selectSymbol(symbol, 'buy', false); toDashboard(symbol);"><span class="d-inline-block fw-bold fz-14"><span>{{ symbol.front_view }}</span></span></th>
                            <td><span class="d-inline-block fw-bold fz-14"><span>{{ symbol.bid }}</span></span></td>
                            <td><span class="d-inline-block fw-bold fz-14">
                                <button @click="selectSymbol(symbol, 'sell');" class="btn btn-danger">
                                  {{ $t("dashboard.sell") }}
                                </button>
                              </span>
                            </td>
                            <td><span class="d-inline-block fw-bold fz-14"><span>{{ symbol.ask }}</span></span></td>
                            <td><span class="d-inline-block fw-bold fz-14">
                                <button @click="selectSymbol(symbol, 'buy');" class="btn btn-primary">
                                  {{ $t("dashboard.buy") }}
                                </button>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-show="this.symbolsFilter === 'stock' && this.stockCountry === 'united-states' && !this.isStockLoading">
                        <div><div class="text-center loading">
                          <a href="#" class="btn btn-primary stock-loading" @click="handleLoadMore()">Load more</a>
                        </div></div>
                      </div>
                      <div v-show="isStockLoading && this.offset < 10">
                        <div class="text-center loading stock-loading btn btn-danger">
                          Loading..
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import BuySellDeal from "./BuySellDeal.vue";
// { name: "russia", id: 7},
export default {
  name: "DashboardCup",
  components: {
    vSelect,
    BuySellDeal
  },
  data(){
    return{
      selectedCountry: { name: "united-states", id: 1, view: "USA" },
      countryIds: [
        { name: "united-states", id: 1, view: "USA" },
        { name: "switzerland", id: 2, view: "Switzerland" },
        { name: "spain", id: 4, view: "Spain"},
        { name: "united-kingdom", id: 3, view: "United Kingdom"},
        { name: "france", id: 5, view: "France"},
        { name: "germany", id: 6, view: "Germany"},
        { name: "china", id: 8, view: "China"}
      ],
      isShow: false,
      showSpinner: true,
      isStockLoading: false,
    }
  },
  computed: {
    ...mapState(["symbols", "symbolsFilter", "stockCountry", "dealSymbol"]),
    filteredSymbols: function() {

      const filtered = this.symbols.filter(symbol => {
        return this.symbolsFilter === symbol.symbol_type.name;
      });

      return filtered;
    },
    showModal(){
      if(this.isShow){
        return true;
      }else{
        return false;
      }
    }
  },
  methods:{
    handleLoadMore() {
      
        if (
          this.symbolsFilter === "stock" &&
          this.stockCountry === "united-states" &&
          !this.isStockLoading
        ) {
          if (this.offset !== 1 && this.symbols.length === 50) {
            this.offset = 1;
          }
          this.offset += 1;
          this.isStockLoading = true;
          if (this.offset < 11) {
            this.$store
              .dispatch("fetchStockSymbols", {
                symbolsType: "stock",
                countryId: 1,
                offset: this.offset,
                query: ""
              })
              .then(() => {
                this.isStockLoading = false;
              })
              .catch(() => {
                if (this.offset > 10) {
                  this.isStockLoading = true;
                }
              });
          }
        }
      
    },
    showChart: function(symbol) {
      this.$store.dispatch("fetchKLines", { symbol: symbol, interval: "D1" });
      this.$store.dispatch(
        "sendMessage",
        JSON.stringify({
          join: "klines_d1_" + symbol.symbol.toLowerCase(),
          notificator: "notificator"
        })
      );      
    },
    isOpenTrade: function () {
      this.toDashboard(this.dealSymbol);
    },
    toDashboard: function(symbol) {
      this.showChart(symbol);
      this.$router.push("/terminal");
    },
    selectSymbol: function(symbol, direct, openModal = true) {
      this.$store.commit("SET_DEAL_SYMBOL", symbol);
      this.$store.commit("SET_DEAL_DIRECT", direct);
      if (openModal) {
        this.openModal()
      }
    },
    setStockCountry: function() {
      
      this.$store.dispatch("setStockCountry", this.selectedCountry["name"]);
      this.fetchSymbols();
      
    },
    fetchSymbols: function() {
      this.$store.dispatch("fetchSymbols", {
        symbolsType: "stock",
        countryId: this.selectedCountry["id"],
        offset: 1,
        query: ""
      });
    },
    closeModal(){
      this.isShow = false;
    },
    openModal(){     
      this.isShow = true;
    }
    
  },
  watch: {
    selectedCountry(){
      this.setStockCountry()
    }
  }
};
</script>
<style scoped>
  .mrl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

.stock-loading {
  width: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.symbol-name:hover {
  background-color: #ffc007;
  cursor: pointer !important;
}

.admin-wrapper{
  height: 100vh;
  padding-top: 0; 
  padding-bottom: 0;
}

.admin-wrapper .container-fluid {
  min-height: 300px;
}

.admin-wrapper.open {
  overflow: hidden !important;
  padding-top: 0; 
  padding-bottom: 0;
}

.card-body {
  padding-right: 0.2rem !important;
  padding-left: 0.2rem !important;
}

.row-mt-12 {
  margin-top: 12px;
}

.container-fluid > .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  }

.container-fluid {
  padding-top: 12px;
  padding-bottom: 12px;
}

</style>
 

<style>
.velmld-overlay.row.mrl-0 {
  margin-top: 12px;
  border-radius: 1rem;
}

.container-fluid.velmld-parent {
  color: #fff;
  margin-left: 0;
  width: calc(100%);
}

/* div.v-select {
  background-color: #fff !important;
} */
/* 
div.v-select .vs__dropdown-toggle{
  background-color: #fff !important;
} */

#vs1__listbox {
  /* background-color: #fff !important; */
  color: #c2d4f8;
}

.vs__selected {
  color: #5e5e5f;
}

.vs__actions svg {
    fill: #5e5e5f;
}
</style>
